<template>
  <div
    :class="{
      [$style.input_wrapper]: true,
      [$style[size]]: true,
      [$style.disabled]: disabled,
      [$style.hover]: hovered,
      [$style.focus]: focused,
      [$style.error]: hasError,
    }"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    @click.stop="focus"
  >
    <div
      ref="leftIconRef"
      :class="[
        $style.icon,
        Boolean($slots.leftIcon) ? $style.left_icon : $style.none,
      ]"
    >
      <slot name="leftIcon" />
    </div>
    <input
      v-model="model"
      ref="el"
      :class="$style.input"
      :placeholder="placeholder"
      :disabled="disabled"
      data-test="input"
      :type="type"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <span
      v-if="optionsButton"
      :class="$style['option-btn']"
      >Изменить</span
    >
    <div
      ref="rightIconRef"
      :class="[
        $style.icon,
        Boolean($slots.rightIcon) ? $style.right_icon : $style.none,
      ]"
    >
      <slot name="rightIcon" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useIMask } from "vue-imask";
import type { FactoryOpts } from "imask";

const props = withDefaults(
  defineProps<{
    size?: "l" | "m";
    hasError?: boolean;
    disabled?: boolean;
    optionsButton?: boolean;
    placeholder?: string;
    postfix?: string;
    type?: string;
    // опции маски
    maskOptions?: FactoryOpts;
  }>(),
  {
    size: "m",
    hasError: false,
    disabled: false,
    optionsButton: false,
    placeholder: "",
    type: "text",
    maskOptions: () => ({
      mask: /.*/,
    }),
  },
);

const { size, hasError, disabled, placeholder, maskOptions } = toRefs(props);

const model = defineModel();

const emit = defineEmits<{
  (e: "complete", data: boolean): void;
  (e: "unmasked", data: boolean): void;
  (e: "set-unmasked", data: string): void;
  (e: "onFocus"): void;
  (e: "onBlur"): void;
}>();

const { el, masked, unmasked } = useIMask(maskOptions, {
  onAccept,
  onComplete,
});
const focused = ref(false);
const hovered = ref(false);

masked.value = model.value?.toString() ?? "";

watch(model, (v) => {
  masked.value = v?.toString() ?? "";
});

const focus = () => {
  (el.value as HTMLInputElement)?.focus();
};

function handleFocus() {
  focused.value = true;
  emit("onFocus");
}

function handleBlur() {
  focused.value = false;
  emit("onBlur");
}

function onAccept(e?: InputEvent) {
  emit("complete", false);
  if (!e) return;
  model.value = (e.target as HTMLInputElement).value;
}

function onComplete(e?: InputEvent) {
  if (!e) {
    emit("complete", false);
    return;
  }

  emit("set-unmasked", unmasked.value);
  emit("complete", true);
}
defineExpose({
  focus,
});
</script>

<style lang="scss" module>
.input_wrapper,
.input {
  width: 100%;
}
.input_wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-2xl);
  border-radius: var(--radius-max);
  background: var(--input-default);
  color: var(--general-transparent-light-35);
  gap: 12px;
  transition: 0.25s;
  cursor: text;

  .option-btn {
    color: var(--button-primary-default);
    font-size: 12px;
    cursor: pointer;
  }

  &.hover {
    background: var(--input-hover);
    color: var(--general-transparent-light-35);
    .input {
      color: var(--general-transparent-light-35);
    }
  }
  &.focus {
    color: var(--general-transparent-light-100);
    background: var(--input-hover);
    .input {
      color: var(--general-transparent-light-100);
    }
  }
  &.disabled {
    cursor: not-allowed;
    background: var(--input-disabled);
    .input {
      cursor: not-allowed;
      color: var(--general-transparent-light-35);
    }
  }
}
.input {
  outline: none;
  border: none;
  background: transparent;
  color: var(--general-transparent-light-35);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.07px;

  &::-webkit-input-placeholder {
    font-family: $regular;
  }
  &:-ms-input-placeholder {
    font-family: $regular;
  }
  &:-moz-placeholder {
    font-family: $regular;
  }
  &:-moz-placeholder {
    font-family: $regular;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  border: 1px solid var(--optional-red);
}

.l {
  height: 54px;
}
.m {
  height: 44px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left_icon {
  flex: 0 0;
}
.right_icon {
  flex: 0 0;
}
.none {
  display: none;
}
</style>
